.mouse-guideline {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9));
  pointer-events: none;

  .mouse-guideline-img {
    position: absolute;
    bottom: 130px;
    left: 50%;
    width: auto;
    height: 100px;
    transform: translateX(-50%);
    animation: mouseRotate 4.5s infinite;
  }

  .mouse-guideline-text {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 90px;
    text-align: center;
    color: white;
    text-transform: uppercase;
    line-height: 25px;
    font-size: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
  }

  @media screen and (min-width: 481px) {
    height: 250px;

    .mouse-guideline-img {
      bottom: 50px;
    }

    .mouse-guideline-text {
      bottom: 10px;
    }

  }
}

@keyframes mouseRotate {
  0% {
    transform: translateX(-50%) rotate(0deg);
  }

  10% {
    transform: translateX(-50%) rotate(-45deg);
  }

  20% {
    transform: translateX(-50%) rotate(45deg);
  }

  30% {
    transform: translateX(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) rotate(0deg);
  }
}