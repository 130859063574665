.World {
  position: fixed;
  width: auto;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  .World-Canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .Canvas-3D {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .World-2D-Contents {
    position: absolute;
    top: 0;
    left: 0;

    &.no-ui,
    &.preview {
      .tour-infor {
        display: none;
      }
      .menuV2-desktop--container {
        display: none;
      }
      .powered-container {
        pointer-events: none;
      }
      & ~ .desktop-vz-landing,
      & ~ .phone-vz-landing {
        opacity: 0;
        visibility: hidden;
      }

      .menuV2-mobile--container {
        .vz-logo {
          pointer-events: none;
        }
        .menuV2-mobile--icons > .icons {
          display: none;
        }
        .menuV2-mobile--top,
        .menuV2-mobile--header {
          display: none;
        }
      }
    }
  }
}
