.hover-container {
  position: relative;
  cursor: pointer;
}

.tooltip {
  z-index: 9999;
  white-space: nowrap;
  font-size: 14px;
  padding: 9px 12px 10px;
  position: absolute;
  background-color: #052044e0;
  color: white;
  border-radius: 3px;
  transform: translate(-50%, -100%);
  pointer-events: none;
  text-transform: capitalize;

  &.primeStyle {
    background-color: #1f5968;
  }
}
