.map-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  animation: fadeFade 1.5s ease-in-out 0s infinite normal;
}

@-webkit-keyframes fadeFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeFade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

