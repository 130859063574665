@keyframes hsPulse {
  0% {
    width: 20px;
    height: 20px;
    opacity: 0;
  }

  10% {
    width: 20px;
    height: 20px;
    opacity: 1;
  }

  100% {
    width: 60px;
    height: 60px;
    opacity: 0;
  }
}

.hs-icon-pulse {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  // background-color: aquamarine;

  .pulse {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    background-color: white;
    border: 1px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
    animation: hsPulse 1.5s infinite;
    animation-timing-function: ease-in-out;
  }
}