.tour-menu.menu-gallery {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);

  @media (min-width: 481px) and (max-width: 1023px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 100vh;
    padding: 20px 10px 40px 10px;
  }

  @media (min-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15vw;
    max-width: 200px;
    min-width: 200px;
    height: 100vh;
    padding: 20px 10px 40px 10px;
  }

  .arrow-up,
  .arrow-down,
  .arrow-right,
  .arrow-left {
    position: absolute;
    font-size: 20px;
    line-height: 1;
    color: white;
  }

  .arrow-up {
    top: 0;
    rotate: 90deg;
  }

  .arrow-down {
    bottom: 22px;
    rotate: -90deg;
  }

  .arrow-right {
    bottom: 50%;
    right: 2px;
    transform: translateY(-50%);
    rotate: 180deg;
  }

  .arrow-left {
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
  }

  .menu-items {
    height: 100%;
    width: 100%;

    @media (min-width: 481px) {
      .scrollbar-container.ps.ps--active-y {
        .ps__rail-y {
          left: 0;
        }

        .ps__thumb-y {
          position: absolute;
          left: 0;
        }
      }
    }

    .menu-card {
      display: inline-block;
      position: relative;
      aspect-ratio: 16 / 9;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 10px;
      border: 2px solid transparent;

      @media (min-width: 481px) {
        position: relative;
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 5px;
        border: 2px solid transparent;
        cursor: pointer;
      }

      .menu-image {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }

      .menu-title {
        position: absolute;
        top: 5px;
        left: 5px;
        padding: 4px 10px;
        border-radius: 12px;
        color: white;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    .menu-card:last-child {
      margin-bottom: 0;
    }
  }

  .menu-items.menu-nonwrap {
    white-space: nowrap;
    overflow-x: hidden;
  }

  .menu-toggle {
    position: absolute;
    top: -26px;
    color: white;
    width: 60px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    background-color: rgba(0, 0, 0, 0.85);

    @media (min-width: 481px) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
      color: white;
      width: 30px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
    }

    .menu-arrow-left {
      font-size: 20px;
      line-height: 1;
      transition: transform 0.3s ease;
    }

    .menu-arrow-down {
      font-size: 20px;
      line-height: 1;
      transform: rotate(-90deg);
      transition: transform 0.3s ease;
    }
  }

  &.menu-close {
    transform: translateY(100%);
    transition: transform 0.3s ease;

    @media (min-width: 481px) {
      transform: translateX(-100%);
    }
    .menu-toggle {
      .menu-arrow-left {
        transform: rotate(180deg);
      }
      .menu-arrow-down {
        transform: rotate(90deg);
      }
    }
  }

  &.menu-open {
    transform: translateY(0);
    transition: transform 0.3s ease;

    @media (min-width: 481px) {
      transform: translateX(0);
    }
  }

  &.desktop .menu-arrow-down {
    display: none;
  }

  &.mobile .menu-arrow-left {
    display: none;
  }
}
