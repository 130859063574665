.compass-container {
  position: fixed;
  z-index: 1;
  bottom: 60px;
  right: 10px;
  width: 100px;
  height: 100px;

  .compass-icon {
    img {
      width: 100%;
      border-radius: 50%;
      box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.5);
    }
  }

  .compass-arrow {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 2px);
    width: 5px;
    height: 40px;
    z-index: 2;
    transform-origin: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    bottom: 10px;
    right: 10px;
  }

  @media screen and (min-width: 992px) {
    bottom: 15px;
    right: 15px;
    width: 150px;
    height: 150px;

    .compass-arrow {
      top: calc(50% - 35px);
      left: calc(50% - 8px);
      width: 16px;
      height: 70px;
    }
  }

  @media screen and (min-width: 1024px) {
    bottom: 10px;
    right: 10px;
    z-index: 999;

    .compass-arrow {
      top: calc(50% - 30px);
      left: calc(50% - 6px);
      width: 12px;
      height: 60px;
    }
  }
}
