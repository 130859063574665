$bp-xs: 480px;
$bp-sm: 600px;
$bp-md: 769px;
$bp-lg: 1025px;
$bp-xl: 1441px;

@mixin mobile {
  @media screen and (min-width: #{$bp-xs}) {
    @content;
  }
}
@mixin tablet {
  @media screen and (min-width: #{$bp-sm}) {
    @content;
  }
}
@mixin small-laptop {
  @media screen and (min-width: #{$bp-md}) {
    @content;
  }
}
@mixin laptop {
  @media screen and (min-width: #{$bp-lg}) {
    @content;
  }
}
@mixin desktop {
  @media screen and (min-width: #{$bp-xl}) {
    @content;
  }
}
