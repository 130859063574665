.aquaHotspotFrame {
  transform: translateY(-20px);
  border-radius: 20px !important;

  &-preview-img {
    position: absolute;
    bottom: 105%;
    left: 0;
    width: 100%;
    height: 110px;
    border: 1px solid #d6b623;

    img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }

  &-container {
    width: auto;
    height: auto;
    text-align: center;
    cursor: pointer;
  }

  &-title,
  &-subtitle {
    text-transform: uppercase;
    white-space: nowrap;
  }

  &-subtitle {
    margin-top: 5px;
  }

  &-imgC {
    margin-top: 5px;

    img {
      max-width: 80px;
      max-height: 40px;
      object-fit: cover;
    }
  }
}