.prime-modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  inset: 0;
  transition: 0.3s ease;
  opacity: 1;
  z-index: 11;
  background-color: rgba(black, 0.7);
  pointer-events: none;

  &-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-cancle {
    position: absolute;
    top: -70px;
    left: 50%;
    pointer-events: all;
    margin-bottom: 55px;
    transform: translateX(-50%);

    width: 55px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    background-color: transparent;
    border: none;

    .menu-bar {
      width: 100%;
      height: 5px;
      background-color: white;
      border-radius: 2px;
      transition: 0.3s ease;
      box-shadow: 0 0 1px gray;
      &:focus {
        outline: none;
      }
    }

    .menu-bar:nth-child(1) {
      width: 100%;
      height: 2.5px;
      transform: translateY(8px) rotate(45deg);
    }

    .menu-bar:nth-child(2) {
      width: 100%;
      height: 2.5px;
      transform: translateY(-8px) rotate(-45deg);
    }
  }

  &-map {
    position: relative;
    height: 200px;
    width: 340px;

    .mapfloorplan_container {
      display: block;
      top: unset;
      position: relative;
      width: 100%;
      height: 100%;
      transform: unset;
      right: unset;
      margin: 0;
      padding: 2px;

      .map_image {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      @media (max-width: 767px) {
        background-color: transparent;
      }
    }

    @media (max-width: 767px) {
      height: auto;
    }
  }
}
