$primary-color-passcode: black;
$secondary-color-passcode: white;

.passcode-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.9);

  .popup-passcode {
    position: fixed;
    width: 360px;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $secondary-color-passcode;
    border-radius: 5px;
    border: 1px solid $secondary-color-passcode;

    @media (max-width: 360px) {
      width: calc(100vw - 20px);
    }
  }

  .popup-passcode-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-passcode-title {
    display: block;
    width: fit-content;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    color: $primary-color-passcode;
    margin-bottom: 12px;
  }

  .popup-passcode-description {
    font-size: 18px;
    font-weight: 500;
    color: $primary-color-passcode;
  }

  .form-passcode {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      border: 1px solid #666;
      border-radius: 5px;

      &:focus {
        border-color: #888888;
        box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.32);
        outline: 0;
      }
    }
  }

  .input-passcode {
    width: 100%;
    height: 40px;
    font-size: 18px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.6);
    border: none;
  }

  .error-message {
    width: fit-content;
    height: 24px;
    margin: 8px 0;
    font-size: 18px;
    color: red;
  }

  .access-popup-pc {
    height: 40px;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: $primary-color-passcode;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }

  .access-popup-pc.disabled {
    color: #d1d1d1;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }
}
