@import 'scss/variables.scss';

.Pan-Menu-item {
  color: $white-color;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .Pan-Menu-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    font-size: 12px;
    .icon-check {
      min-width: 15px;
    }
  }

  &.available {
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: $highlight_bg_color;
      border-radius: 5px;
    }
  }
  &.active {
    border-radius: 5px;
  }
  > span {
    line-height: 35px;
    vertical-align: middle;
  }

  .Pan-Menu-item-check,
  .Pan-Menu-item-type {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: transparent;
  }

  .Pan-Menu-item-check {
    background-image: url('./icons_menu.png');
    background-size: 360px 45px;
    background-position: -340px -25px;
    &.checked {
      background-position: -60px 0;
    }
  }
  .Pan-Menu-item-type {
    margin-right: 10px;
    line-height: 20px;
    background-position: -340px -25px;

    &.Pano-type {
      &-walk,
      &-GROUND,
      &-Ground,
      &-LANDING,
      &-Landing {
        background-position: -0px -0px;
      }
      &-bird {
        background-position: -20px -0px;
      }
      &-heli,
      &-AERIAL,
      &-Aerial {
        background-position: -80px -0px;
      }
      &-house {
        background-position: -100px -0px;
      }
      &-tree {
        background-position: -120px -0px;
      }
      &-cup {
        background-position: -140px -0px;
      }
      &-shop {
        background-position: -160px -0px;
      }
      &-fish {
        background-position: -180px -0px;
      }
      &-animal {
        background-position: -200px -0px;
      }
      &-swim {
        background-position: -220px -0px;
      }
      &-school {
        background-position: -240px -0px;
      }
      &-tent {
        background-position: -260px -0px;
      }
      &-dive {
        background-position: -280px -0px;
      }
      &-fire {
        background-position: -300px -0px;
      }
      &-grass {
        background-position: -320px -0px;
      }
      &-flag {
        background-position: -340px -0px;
      }
    }
  }

  .Pan-Menu-item-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 9px;
    text-shadow: 0 0 3px black;
  }
}
