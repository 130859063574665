.Pan-Menu {
  pointer-events: none;
  overflow: hidden;
  display: flex;
  border-top: 0;
  border-radius: 10px;
  transition: height 0.5s ease-in-out;

  @media (max-width: 480px) {
    border-radius: 0;
  }

  &.Nav-Pan-open {
    height: calc(100vh - 70px);
    height: calc(calc(var(--vh, 1vh) * 100) - 70px);

    @include tablet {
      height: 70vh;
    }
  }

  &.Nav-Pan-close {
    height: 0;
  }

  &--container {
    display: flex;
    flex: 1;
    flex-direction: column;

    position: relative;
    pointer-events: initial;
  }
  &--header {
    color: $white-color;
    padding: 20px;
    position: relative;
    background: $menu_close_bg_color;

    &__wrapper {
      @include mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &--content {
        .title {
          margin: 0 0 5px;
          font-size: 15px;
          line-height: 1.2rem;
          font-weight: $font-weight-light;
        }

        .sub-title {
          margin: 0;
          font-size: 9px;
          font-style: italic;
          font-weight: $font-weight-light;
        }
      }

      &--icon {
        @include tablet {
          display: none;
        }
      }
    }
  }
  &--body {
    flex: 1;
    position: relative;
    background-color: $menu_bg_color;
    &_groups {
      top: 0;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: auto;
      position: absolute;

      .scrollbar-container.ps.ps--active-y {
        .ps__rail-y {
          &:hover,
          &:focus,
          &.ps--clicking {
            background-color: transparent;
          }
          .ps__thumb-y {
            width: 4px;
            right: 5px;
            background-color: $white-color;
          }
        }
      }

      .Menu-group {
        margin-bottom: 15px;

        .Menu-group-title {
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          margin: 0 0 15px;
          color: $white-color;
          text-transform: uppercase;
          font-size: 12px;
          cursor: pointer;
          .Menu-group-underline {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $white-color;
            p {
            }

            svg {
              width: 10px;
              height: 10px;
            }
          }
        }

        .Menu-group-items {
          padding: 0 15px;
        }
      }
    }
  }
  &.Nav-Pan-left {
    left: 0px;
    top: 44px;
    width: 100vw;

    @media (min-width: 481px) and (max-width: 580px) {
      width: 360px;
    }

    @media (min-width: 581px) {
      width: 450px;
    }

    .Pan-Menu-container {
      width: 30vw;
    }

    @media screen and (max-height: 480px) {
      top: 0;
      bottom: 0;

      .Pan-Menu-container {
        width: 500px;
        padding-top: 20px;
        padding-bottom: 20px;

        .Menu-close-btn.left {
          display: flex;
          width: 50px;
          height: 50vh;
          position: absolute;
          z-index: 9999;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .Menu-bg {
          perspective: none;
          top: -40px;
          bottom: -40px;
          right: -10px;
          background-color: $menu_bg_color;

          .Menu-bg-card {
            display: none;
          }
        }
        .Menu-body {
          .Menu-groups {
            .Menu-group-title,
            .Menu-group-items .Pan-Menu-item {
              padding-right: 40px;
            }
            .scrollbar-container.ps.ps--active-y {
              .ps__rail-y {
                .ps__thumb-y {
                  width: 4px;
                  right: 30px;
                  background-color: $white-color;
                }
              }
            }
          }
        }
      }
    }

    .Pan-Menu-container {
      transform: translateY(-120%);
    }

    &.Nav-Pan-open {
      .Pan-Menu-container {
        transform: translateY(0);
      }
    }
  }

  &.Nav-Pan-bottom {
    bottom: 42px;
    top: auto;
    padding-bottom: 0px;

    .Pan-Menu-container {
      padding-left: 0;
      padding-bottom: 50px;

      .Menu-bg {
        perspective: none;
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: -40px;

        @media screen and (max-width: 575px) {
          height: auto;
        }

        .Menu-bg-card {
          transform: none;
          border-radius: 0;
        }
      }

      .Menu-close-btn.bottom {
        display: flex;
        width: 50vw;
        height: 50px;
        position: absolute;
        z-index: 9999;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media screen and (max-width: 575px) {
          width: auto;
          height: auto;
          top: 8%;
          right: 5%;
          left: auto;
        }
        @include mobile {
          display: none;
        }
      }
    }

    &.Nav-Pan-open {
      .Pan-Menu-container {
        transform: translateY(0);
      }
    }
  }
}
