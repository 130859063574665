@import "../../scss/variables.scss";

.data-item {
  width: 720px;
  margin-bottom: 20px;

  @media screen and (max-width: $size-min-lg) {
    width: 75vw;
  }
  @media screen and (max-width: $size-min-md) {
    width: 85vw;
    margin-bottom: 10px;
  }
  @media screen and (max-width: $size-min-sm) {
    width: 75vw;
  }

  &-text {
    display: none;
    font-size: 14px;
    color: $dark-grey;
    @media screen and (max-width: $size-min-sm) {
      display: block;
    }
  }

  .data-travel {
    display: flex;
    align-items: center;
    &-image {
      flex: 1 0 158px;
      margin-right: 10px;
      border-radius: 5px;

      img {
        width: 100%;
        height: 158px;
        border-radius: 10px;
      }

      @media screen and (max-width: $size-min-sm) {
        flex: 1 0 80px;

        img {
          height: 90px;
        }
      }
    }

    &-content {
      * {
        margin: 0;
        padding: 0;
      }

      &-title {
        font-size: 25px;
        font-family: var(--montserrat-font);
        font-weight: $font-weight-semibold;

        @media screen and (max-width: $size-min-sm) {
          font-size: 18px;
        }
      }
      &-label {
        font-size: 18px;
        color: $light-grey;
        margin-bottom: 18px;
        font-family: var(--montserrat-font);
      }
      &-text {
        font-size: 16px;
        color: $dark-grey;
        font-family: var(--montserrat-font);
        @media screen and (max-width: $size-min-sm) {
          display: none;
        }
      }
    }
  }
}
