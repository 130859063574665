.preview-scene {
  &-image {
    position: absolute;
    top: 0;
    left: 255.5px;
    width: 250px;
    height: 170px;
    padding: 8px;
    background-color: white;
    transition: 0.5s ease;
    pointer-events: none;
    z-index: 2;

    img {
      width: 100%;
      height: calc(100% - 36px);
      object-fit: cover;
    }
  }

  &-title {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      font-size: 12px;
      color: #052044;
      text-transform: uppercase;
    }
  }
}
