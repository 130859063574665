.vz-landing--final-slide {
  position: absolute;
  height: calc(100vh - 388px);
  height: calc(calc(var(--vh, 1vh) * 100) - 388px);
  width: 95vw;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 194px;

  @media (max-width: 320px) {
    top: 130px;
    height: 250px;
  }

  @media (min-width: 321px) and (max-width: 376px) {
    top: 150px;
    height: 300px;
  }

  @include small-laptop {
    position: static;
    height: 70vh;
    width: 43vw;
    margin: 0 auto;
    margin-top: 50px;
    box-shadow: 0px 3px 6px $boxshadow_black;
  }

  &_logo {
    width: 63%;
    height: 100px;
    margin-top: 77px;

    @media (max-width: 320px) {
      margin-top: 50px;
      width: 67%;
    }

    @media (min-width: 321px) and (max-width: 376px) {
      margin-top: 50px;
      width: 67%;
    }

    @media (max-width: 1023px) {
      width: 72%;
      height: 38%;
    }

    @media (min-width: 1024px) {
      width: 60%;
      height: 38%;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &_btn {
    width: 273px;
    height: 50px;
    margin-top: 50px;
    border-radius: 25px;
    background-color: $light_green;
    border-color: transparent;
    box-shadow: 0px 3px 6px $boxshadow_black;
    max-width: 100%;

    color: $white-color;
    font-size: 24px;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      cursor: none;
    }

    @media (max-width: 320px) {
      width: 230px;
      margin-top: 30px;
    }

    @media (min-width: 321px) and (max-width: 376px) {
      margin-top: 30px;
    }

    @include small-laptop {
      width: 300px;
    }
  }

  &_content {
    width: 273px;
    height: 44px;
    margin-top: 30px;
    font-size: 18px;
    text-align: center;

    @media (max-width: 320px) {
      margin-top: 25px;
    }
  }
}
