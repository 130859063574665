.social-icon {
  padding: 20px 10px;
  text-align: center;

  @media screen and (min-width: 575px) {
    padding: 20px 20px;
  }

  @media screen and (min-width: 992px) {
    padding: 20px 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }

  .social-icon-svg {
    display: inline-block;
    padding: 0 5px;

    @media screen and (max-width: 768px) {
      img {
        width: 84px;
        height: 84px;
      }
    }

    @media screen and (max-width: 575px) {
      img {
        width: 84px;
        height: 84px;
      }
    }

    &-background {
      background: black;
      height: 110px;
      @media screen and (min-width: 575px) {
        height: 110px;
      }

      @media screen and (min-width: 992px) {
        height: 110px;
      }

      @media screen and (min-width: 1200px) {
        height: 110px;
      }
    }

    a {
      display: block;
    }
  }

  .social-icon-label {
    padding-top: 10px;
    text-align: center;
    font-size: 1.1rem;

    @media screen and (min-width: 575px) {
      font-size: 1.25rem;
    }

    @media screen and (min-width: 992px) {
      font-size: 1.5rem;
    }

    a,
    a:focus,
    a:visited,
    a:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}
