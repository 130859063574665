@import "../../scss/variables.scss";
@import "../../scss/mixin.scss";

.search-nav {
  // height: 30vh;
  width: 100%;

  background-image: url("./Top_BG.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &-logo {
    position: static;
    padding-top: 4vh;

    text-align: center;

    @include laptop {
      text-align: left;
    }
  }

  &__field {
    padding-top: 5vh;
    width: 100%;
    text-align: center;

    @include tablet {
      padding-top: 4vh;
    }

    @include laptop {
      padding-top: 3vh;
    }

    @include desktop {
      padding-top: 3vh;
    }
  }

  &-title {
    color: $white-color;

    font-size: 1.25rem;
    font-family: var(--montserrat-font);
    font-weight: $font-weight-light;

    margin-bottom: 1rem;

    @include tablet {
      font-size: 1.75rem;
    }

    @include laptop {
      font-size: 3.125rem;
    }

    @include desktop {
      font-size: 3.5rem;
    }
  }
}

.container {
  width: 90%;
  margin: auto;
  position: relative;
  padding-bottom: 20px;

  @include tablet {
    width: 80%;
  }

  @include laptop {
    width: 80%;
  }

  @include desktop {
    width: 60%;
    padding-bottom: 40px;
  }
}

.search-data {
  width: 100%;
  height: auto;
  text-align: center;
  // padding-top: 10px;
}

.item {
  @extend .container;
  display: flex;
  flex-direction: column;
  &--wrapper {
    width: 100%;

    display: flex;
    &-img {
      // width: 40%;
      width: 100px;
      height: 100px;

      margin-right: 10px;
      border-radius: 8px;

      background-size: cover;
      background-position: center center;

      @include tablet {
        width: 200px;
        height: 200px;
      }

      @include laptop {
        margin-right: 20px;
      }

      @include desktop {
        margin-right: 30px;
      }
    }

    &-content {
      text-align: left;
      width: calc(100% - 110px);
      &__title {
        font-size: 1.8rem;
        font-weight: $font-weight-semibold;
        text-decoration: none;
        color: $primary-color;

        &-label {
          font-size: 1.2rem;
          color: $light-grey;
        }

        &-tagline {
          display: none;
          text-align: left;

          font-size: 1rem;
          margin-top: 10px;

          @include tablet {
            display: block;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.notification {
  @extend .container;
  &__not-found {
    font-size: 1rem;
    margin-top: 10px;

    @include tablet {
      font-size: 1.2rem;
      padding-top: 12px;
    }

    @include laptop {
      font-size: 1.8rem;
      padding-top: 15px;
    }

    @include desktop {
      font-size: 2rem;
      padding-top: 20px;
    }
  }

  &__info {
    font-size: 1rem;
    margin-top: 10px;

    @include tablet {
      padding-top: 12px;
      font-size: 1.2rem;
    }

    @include laptop {
      padding-top: 15px;
      font-size: 1.8rem;
    }

    @include desktop {
      padding-top: 20px;
      font-size: 2rem;
    }
  }
}

.in-md-screen {
  display: block;

  @include tablet {
    display: none;
  }
}

.logo-img {
  display: static;
  width: 40%;
  margin: 0;

  @include tablet {
    width: 30%;
  }
  @include laptop {
    width: 20%;
  }
  @include desktop {
    width: 15%;
  }
}
