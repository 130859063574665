.hotspot-container {
  position: absolute;
  transform-origin: center;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  pointer-events: none;

  .hotspot-transform {
    transform-origin: center;
    pointer-events: all;
  }

  .air-balloon-icon {
    width: 75px;
    height: 80px;
    transform: translate(-8px, -4px);
    border: none;
    box-shadow: none;
  }
}
