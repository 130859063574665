.khp-indoor-hotspot {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  // background-color: white;
  // box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
  }

  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    // border: 1px solid #d6b623;
    background-color: transparent;
    animation: indoorPulsePoint 1s infinite;
  }
}

@keyframes indoorPulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}