@import '../../scss/mixin.scss';
.wrapper {
  text-align: center;
  background-color: transparent;
  width: 120px;
  @media screen and (max-width: 768px) {
    width: 84px;
  }

  @media screen and (max-width: 575px) {
    width: 70px;
  }
}
.powered-img {
  width: 100%;
  @include laptop {
    width: 70%;
    height: auto;
    padding: 0;
  }

  &-logov2 {
    width: 150px !important;
  }
}
