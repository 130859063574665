@import "../../scss/variables.scss";

.intro-slide-container {
  width: 100%;
  height: 100%;
  .intro-slide-item-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .intro-slide-logo {
      width: 140px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .intro-slide-group {
      display: flex;
    }
    .intro-slide-item {
      outline: none !important;
      .intro-slide-item-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 3.5rem;

        .intro-img {
          width: 124px;
          height: 125px;
        }

        .intro-slide-title {
          font-size: 30px;
          font-family: var(--advent-pro-font);
        }

        // @media screen and (min-width: 1200px) {
        //   padding: 0 50px;
        // }

        // &.pr-1 {
        //   padding-right: 1px;
        // }

        .intro-svg {
          width: 100%;
          position: relative;

          .intro-logo {
            width: 70%;
            max-width: 150px;
            margin-left: auto;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }

        .intro-text {
          // color: rgba(255, 255, 255, 0.5);
          color: $text-grey;

          font-family: var(--montserrat-font);
          font-size: 1.125rem;
          font-weight: $font-weight-regular;

          padding-top: 1rem;
          height: 4rem;
          line-height: 1.375;
          text-align: center;

          &.text-right {
            text-align: right;
          }

          @media screen and (min-width: 992px) {
            font-size: 15px;
            padding-top: 0.5rem;
            height: 0.9rem * 2 + 0.5rem;
            // line-height: 0.9rem;
          }

          @media screen and (min-width: 1400px) {
            font-size: 1rem;
            padding-top: 0.9rem;
            height: 1.375rem * 3 + 1rem;
            // line-height: 0.9rem;
          }
        }
      }
    }
  }

  &.phone {
    &.landscape {
      .intro-slide-item-list {
        .intro-slide-item {
          .intro-slide-item-body {
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 0;

            .intro-text {
              padding-top: 0.5rem;
              height: 2.5rem;
            }
            .intro-svg {
              max-width: 25vw;
            }
          }
        }
      }
    }
    &.portrait {
      .intro-slide-item-list {
        .intro-slide-item {
          .intro-slide-item-body {
            .intro-svg {
              max-width: 50vw;
            }
          }
        }
      }
    }
  }

  &.desktop {
    .intro-slide-item-list {
      .intro-slide-item {
        .intro-slide-item-body {
          .intro-svg {
            max-width: 20vw;
          }
        }
      }
    }
  }
}

.slick-slider {
  .slick-track {
    display: flex !important;
    .slick-slide {
      height: inherit !important;
      > div {
        outline: none !important;
      }
    }
  }
}

.slick-dots {
  li button:before {
    color: white !important;
    font-size: 10px !important;
    opacity: 0.5 !important;
  }
  li.slick-active button:before {
    color: white !important;
    opacity: 0.9 !important;
  }
}
