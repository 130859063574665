.desktop-vz-landing {
  @extend .full-dimentions;
  @extend .center-div;
  position: fixed;

  &--body {
    width: 95vw;
    height: 80vh;
    background-color: $intro-bg-color;
    display: flex;

    @include laptop {
      width: 90vw;
    }
    &_welcome {
      width: calc(95vw / 2);
      @include laptop {
        width: 43vw;
        padding-right: 5vw;
      }
    }
    .final-slide {
      &_welcome {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 65vw;
        height: 12vh;
        margin-top: 8vh;
      }
      &_title {
        margin: 0;
        width: auto;
      }
      &_next-icon {
        margin: 0;
        margin-left: 15px;
        justify-content: flex-start;
        @include desktop {
          margin-left: 0px;
        }
      }
    }
    &_title {
      margin-top: 20vh;
      margin-left: 5vw;
      margin-right: 2vw;
      display: flex;
      justify-content: flex-end;
      p {
        font-size: 2.5rem;
        font-weight: $font-weight-semibold;
        letter-spacing: 0px;
        @include small-laptop {
          font-size: 3.5rem;
        }
        @include desktop {
          font-size: 6rem;
        }
      }
      @include laptop {
        margin-right: 0;
      }
      @include desktop {
        width: 100%;
        margin-left: 0;
      }
    }

    &_next-icon {
      height: 129px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 5vw;
      margin-right: 2vw;
      @include laptop {
        margin-right: 0;
        margin-left: 10vw;
      }
      @include desktop {
        width: 27vw;
      }
    }

    &_circle-left {
      width: 40px;
      height: 40px;
      border: 2px solid $black-color;
      border-radius: 50%;

      position: relative;
      @include laptop {
        width: 61px;
        height: 61px;
      }
      &::after {
        content: '';
        position: absolute;
        width: 55px;
        height: 2px;
        top: 19px;
        left: 40px;

        background-image: linear-gradient(
          to right,
          $black-color 0%,
          $black-color 50%,
          transparent 50%
        );
        background-size: 10px 5px;
        background-repeat: repeat-x;
        @include laptop {
          width: 90px;
          top: 27px;
          left: 59px;
          background-size: 15px 5px;
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 15px;
        left: 88px;
        border: solid $black-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);

        @include laptop {
          top: 20px;
          left: 130px;
          padding: 7px;
        }
      }
    }

    &_cirle-right {
      width: 80px;
      height: 80px;
      background-color: $white-color;
      opacity: 1;
      border-radius: 50%;
      margin-left: 15px;
      @include laptop {
        width: 120px;
        height: 120px;
        margin-left: 25px;
      }
    }
    &_slide-show {
      margin: 0 auto;
      overflow: hidden;
      max-width: calc(850px - 50px);
      position: relative;

      @include tablet {
        max-width: 43vw;
      }
      &_slider {
        transition: ease 1000ms;
        white-space: nowrap;
      }
      &_slides {
        display: inline-block;
      }
      &_dots {
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 9vh;
        @include small-laptop {
          bottom: 10vh;
        }
        @include desktop {
          bottom: 13vh;
        }
      }
      &_dot {
        width: 12px;
        height: 12px;
        border: 1px solid $grey-color;
        border-radius: 10px;

        display: inline-block;
        border-radius: 50%;
        margin: 0px 10px 0px;
        bottom: 68px;
      }
      .active {
        border-color: $light-green;
      }
      &_skip {
        font-size: 16px;
        text-decoration: underline;
        color: $grey_color;

        position: absolute;
        bottom: 6vh;
        left: 50%;
        transform: translate(-50%, 0);

        cursor: pointer;
        @include small-laptop {
          bottom: 7vh;
        }
        @include desktop {
          bottom: 10vh;
        }
      }
    }
    .hide {
      display: none;
    }
  }

  &--footer {
    width: 100%;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 4vh;
    left: 0;

    &_content {
      display: inline-block;
      font-size: 16px;
      font-weight: lighter;
      color: $white-color;
      margin-right: 10px;
    }
    img {
      width: 64.5px;
    }
  }
}
