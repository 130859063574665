.vz-landing--intro-slide {
  height: calc(100vh - 307px);
  height: calc(calc(var(--vh, 1vh) * 100) - 307px);
  width: calc(100vw - 32px);
  margin-top: 40px;
  background-color: $white-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px $boxshadow_black;

  @media (max-width: 320px) {
    height: calc(calc(var(--vh, 1vh) * 100) - 212px);
    width: 95vw;
  }

  @media (max-width: 480px) {
    margin-top: 22px;
    width: 95vw;
  }

  @media (min-width: 767px) {
    height: 70vh;
    height: calc(calc(var(--vh, 1vh) * 70));
    width: 43vw;
    margin-top: 50px;
  }

  &_image {
    margin-top: 18px;
    width: 160px;
    height: 140px;

    @media (max-width: 320px) {
      width: 86px;
      height: 77.4px;
    }

    @media (min-width: 321px) and (max-width: 360px) {
      width: 112px;
      height: 98px;
    }

    @include small-laptop {
      margin-top: 26px;
      width: 176px;
      height: 154px;
    }

    @include desktop {
      margin-top: 26px;
      width: 200px;
      height: 180px;
    }
  }

  &_dot-line {
    width: 100px;
    height: 2px;
    top: 10px;
    left: 22px;
    margin-top: 4vh;
    margin-bottom: 3vh;

    background-image: linear-gradient(
      to right,
      $black-color 0%,
      $black-color 50%,
      transparent 50%
    );
    background-size: 8px 2px;
    background-repeat: repeat-x;

    @include small-laptop {
      margin-top: 3vh;
      margin-bottom: 3vh;
      background-size: 15px 5px;
    }
  }

  &_title {
    text-align: center;
    p {
      font-size: 36px;
      font-weight: $font-weight-semibold;

      @media (max-width: 320px) {
        font-size: 28px;
      }

      @include laptop {
        font-size: 50px;
        margin-bottom: 20px;
      }
    }
  }

  &_content {
    width: 80vw;
    height: 6.5vh;
    height: calc(calc(var(--vh, 1vh) * 6.5));
    font-size: 18px;
    text-align: center;
    white-space: break-spaces;

    @media (max-width: 320px) {
      font-size: 16px;
      width: 90vw;
    }

    @media (max-width: 480px) {
      width: 90vw;
    }

    @media (min-width: 767px) {
      width: 75%;
      font-size: 1.5em;
    }

    @include laptop {
      height: 8.5vh;
      font-size: 22px;
    }

    @include desktop {
      height: 8.5vh;
      font-size: 24px;
    }
  }
}
