.scale-arrow {
  animation: scaleArrow 1s infinite;

  &.white {
    color: white;
    animation: scaleArrowWhite 1s infinite;
  }

  &.black {
    color: black;
    animation: scaleArrowBlack 1s infinite;
  }

  @keyframes scaleArrowWhite {
    0% {
      transform: scale(1);
      color: white;
    }

    50% {
      transform: scale(1.8);
      color: #f9c468;
    }

    100% {
      transform: scale(1);
      color: white;
    }
  }

  @keyframes scaleArrowBlack {
    0% {
      transform: scale(1);
      color: black;
    }

    50% {
      transform: scale(1.8);
      color: rgb(191, 127, 16);
    }

    100% {
      transform: scale(1);
      color: black;
    }
  }

  @keyframes scaleArrow {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.8);
      color: rgba(255, 192, 0, 1);
    }

    100% {
      transform: scale(1);
    }
  }
}
