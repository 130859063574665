.hotspot_icon_custom,
.hotspot_icon {
  width: 30px;
  height: 30px;
  @extend .center-div;
  border-radius: 50%;
  background-color: black;
  border: 1px solid white;
  position: absolute;
  z-index: 2;
}
.hotspot_icon_custom {
  background-color: transparent;
  border: none;
}
