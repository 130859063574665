@import "../../scss/variables.scss";

.search-form {
  position: relative;
  &-label {
    position: absolute;
    top: 13px;
    left: 13px;
    img {
      width: 40px;
      height: 40px;
    }
  }
  &-input {
    width: 100%;
    height: 64px;
    padding: 10px 10px 10px 65px;
    border-radius: 90px;
    border: 1px solid #bbc0c4;
    font-size: 20px;
    user-select: text;
    -webkit-user-select: text;

    @media screen and (max-width: $size-min-lg) {
      // width: 75vw;
    }
    @media screen and (max-width: $size-min-md) {
      // width: 85vw;
    }
    @media screen and (max-width: $size-min-sm) {
      // width: 75vw;
    }

    &:focus {
      outline: none;
    }
  }
  &-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    background: inherit;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
}
