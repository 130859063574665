@import 'src/scss/variables.scss';

.phone-vz-landing {
  position: fixed;
  top: 50px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: $intro-bg-color;

  &--welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_title {
      font-size: 36px;
      font-weight: 600;
      font-family: 'Montserrat';
      color: $black-color;
      margin-top: 30px;

      @media (max-width: 320px) {
        margin-top: 22px;
      }
    }

    &_next-icon {
      width: 83px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_circle-left {
      width: 24px;
      height: 24px;
      border: 2px solid $black-color;
      border-radius: 50%;

      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        top: 10px;
        left: 22px;

        background-image: linear-gradient(
          to right,
          $black-color 0%,
          $black-color 50%,
          transparent 50%
        );
        background-size: 8px 2px;
        background-repeat: repeat-x;
      }

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 50px;
        border: solid $black-color;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }

    &_cirle-right {
      width: 50px;
      height: 50px;
      background-color: $white-color;
      opacity: 1;
      border-radius: 50%;
    }
  }

  &--slide-show {
    margin: 0 auto;
    overflow: hidden;
    max-width: calc(100vw - 32px);
    position: relative;

    @media (max-width: 320px) {
      max-width: 95vw;
    }

    @media (max-width: 480px) {
      max-width: 95vw;
    }

    &_slider {
      transition: ease 1000ms;
      white-space: nowrap;
    }

    &_slides {
      display: inline-block;
    }

    &_dots {
      text-align: center;
      position: absolute;
      bottom: 3.5vh;
      bottom: calc(calc(var(--vh, 1vh) * 3.5));
      left: 50%;
      transform: translate(-50%, 0);
      .active {
        border-color: $light-green;
      }

      @media (max-width: 320px) {
        bottom: 20px;
      }
    }

    &_dot {
      width: 10px;
      height: 10px;
      border: 1px solid $grey-color;
      border-radius: 10px;

      display: inline-block;
      margin: 10px 7px;
    }
    &_skip {
      font-size: 14px;
      text-decoration: underline;
      color: $grey_color;
      cursor: pointer;

      position: absolute;
      bottom: 1vh;
      bottom: calc(calc(var(--vh, 1vh)));
      left: 50%;
      transform: translate(-50%, 0);

      @media (max-width: 320px) {
        bottom: 10px;
      }
    }
    .hide {
      display: none;
    }
  }
  .final-slide {
    display: none;
  }
  &--logo {
    width: 100%;
    height: 14px;
    position: fixed;
    bottom: 20px;

    @media (max-width: 320px) {
      bottom: 5px;
    }

    &_content {
      font: normal normal normal 10px/13px Montserrat;
      margin-right: 5px;
      text-shadow: 2px 2px 3px $white-color;
      color: $white-color;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 35.25px;
      height: 10.5px;
    }
  }

  &--footer {
    position: fixed;
    width: 100%;
    height: 20px;
    background-color: $white-color;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    &_line {
      width: 130px;
      height: 4px;
      background-color: $black-color;
      border-radius: 2px;
    }
  }
}
