#followDiv {
  display: none;
  color: white;
  position: absolute;
  z-index: 999;
  font-size: 15px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 10px 30px;
  text-wrap: nowrap;
  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.8);
    top: 100%;
    left: 40%;
  }
}
