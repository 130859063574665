.popup {
  &-container {
    position: fixed;
    z-index: 90;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      background: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(rgba(0, 0, 0, 0.7)),
        color-stop(70%, transparent),
        to(transparent)
      );
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7),
        transparent 70%,
        transparent
      );
    }

    .popup-header {
      position: absolute;
      top: 55px;
      left: 0;
      height: auto;
      z-index: 2;
      width: 100%;
      padding: 0.5rem;

      @media screen and (max-width: 575px) {
        top: 0;
      }

      .Close-button {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        line-height: 2rem;
        color: white;
        cursor: pointer;
        svg {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .popup-body {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      overflow: hidden;
      display: flex;

      &.Menu-left {
        // padding-left: 40px;
      }

      &.Menu-bottom {
        padding-bottom: 40px;
      }

      &-content {
        position: relative;
        width: 100%;
        flex: 1;
      }
    }
  }
}
