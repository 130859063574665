.school-layout {
  gap: 5px;

  &__icon-container {
    position: relative;
    z-index: 2;
    &:hover {
      .school-layout__note-plate {
        opacity: 1;
      }
    }
    .icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    @media (max-width: 767px) {
      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  &__note-plate {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 30px;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.2s ease-in-out;
    pointer-events: none;
    opacity: 0;
    p {
      color: white;
      font-size: 15px;
      white-space: nowrap;
    }
  }

  &__note-menu {
    transform: translate(14px, -56px) scale(1.4) !important;
  }

  &__note-arrow {
    position: absolute;
    top: 50%;
    right: -8px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgba($color: #000000, $alpha: 1);
    transform: translateY(-50%);

    &__autoplay {
      right: -9.5px;
    }

    &__contact {
      right: -9.5px;
    }

    &__menu {
      top: 37px;
      right: 73px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 1);
    }
  }

  @media (max-width: 481px) {
    gap: 10px;
  }
}
