.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);

  &::before {
    display: block;
    position: relative;
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid #fff;
    animation: pulsePoint 1s infinite;
  }

  &.primeStyle {
    background-color: transparent;
    border: 5px solid rgba(214, 181, 35, 0.6);

    &::before {
      border: 1px solid #d6b623;
      background-color: #d6b623;
      animation: yellowPulsePoint 1s infinite;
    }
  }
}

@keyframes pulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes yellowPulsePoint {
  0% {
    box-shadow: 0 0 0 0 rgba(214, 182, 35, 0.7);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}