.topview {
  &-scenes {
    pointer-events: none;
    padding: 0 5px;
    position: absolute;
    top: 0;
    left: 246px;
    transition: 0.5s ease;
    opacity: 1;
    z-index: 2;

    @media (max-width: 1023px) {
      position: relative;
      left: unset;
      top: unset;
      max-height: 500px;
      padding: 0;
      &.hovering {
      }
    }
  }

  &-item {
    z-index: 10;
    width: 250px;
    height: 50px;
    padding: 0 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);
    pointer-events: all;

    &.position-relative {
      position: relative;
    }

    &:hover {
      .topview-title {
        font-weight: bold;
      }
    }

    @media (max-width: 1023px) {
      width: 100%;
      background-color: transparent;
      padding: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: unset;
    }
  }

  &-title {
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;

    &.current-scene {
      font-weight: bold;
      color: #f9c468;
    }
  }

  &-preview-container {
    pointer-events: none;
  }
}
