.aqua-modal {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  &-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle at 60% 40%, #104d9d 0%, #03325c 65%);

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      border-left-color: #09f;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
      margin: 20px auto;
    }

    &::after {
      position: absolute;
      content: '';
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background: url(./bg-body.svg) left bottom !important;
    }
  }

  &-iframe__wrapper {
    position: relative;
    height: 540px;
    width: 960px;
    z-index: 2;
    iframe {
      height: 540px;
      width: 960px;
      position: relative;
    }

    @media (max-width: 767px) {
      height: auto;
      width: auto;
      iframe {
        &.youtube {
          height: 185px;
          width: 325px;
        }

        &.map {
          height: 600px;
          width: 335px;
        }
      }
    }
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
  }

  &-close__btn {
    font-family: EB Garamond, sans-serif;
    display: flex;
    height: 40px;
    color: white;
    padding: 0px 10px;
    line-height: 1.4;
    font-size: 30px;
    border: none;
    cursor: pointer;
    background-color: #0d3e7e;
    opacity: 0.85;
    &:hover {
      opacity: 1;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
