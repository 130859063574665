@import '../../scss/variables.scss';
@import '../../scss/mixin.scss';

$introTextShadow: 1px -1px 1px rgba(0, 0, 0, 0.1),
  1px 1px 1px rgba(0, 0, 0, 0.4);

// .landing-intro-back {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   z-index: 9997;
//   background: rgba(0, 0, 0, 0.5);
//   background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, transparent 70%, transparent 100%);
//   mix-blend-mode: color-burn;
// }

.landing-intro {
  position: fixed;
  left: 37.5%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 62.5%;
  height: 100vh;

  z-index: 9998;
  background-color: #ffff;
  transition: all 1s ease-in-out;

  &.out {
    opacity: 0;
  }

  // font-family: var(--montserrat-font);

  @media screen and (max-width: $size-min-lg) {
    left: 0%;
    width: 100%;
    background: none;
  }

  &.menu-left {
    // padding-left: 70px;

    @media screen and (max-width: $size-min-lg) {
      padding-left: 0;
    }
  }

  &.menu-bottom {
    // padding-bottom: 70px;
  }

  .intro-title,
  .intro-slide {
    align-self: stretch;
    position: relative;
    transition: all 1s ease-in-out;
  }

  .intro-slide-title {
    color: white;
    transition: all 1s ease-in-out;

    @include laptop {
      color: black;
    }
  }

  .intro-title {
    position: relative;
    padding-bottom: 5vh;

    @media screen and (max-width: $size-min-lg) {
      strong {
        color: $white-color;
      }
    }

    &-logo {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.phone {
    top: 0;
    bottom: 0;
    height: auto;

    &.portrait {
      .intro-title {
        padding-bottom: 0;
      }
    }

    &.landscape {
      padding-bottom: 40px;
    }
  }

  .intro-title {
    // flex: 1;
    @include laptop {
      flex: 1;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    p {
      color: color;

      font-family: var(--montserrat-font);
      font-size: 90px;
      font-weight: $font-weight-bold;

      text-align: center;
      text-shadow: $introTextShadow;

      line-height: 1.2;

      padding-bottom: 6px;
      margin: 0;

      @media screen and (min-width: 1400px) {
        font-size: 6rem;
      }
      @media screen and (max-width: 992px) {
        font-size: 4rem;
      }
      @media screen and (max-width: 491px) {
        font-size: 3rem;
        margin-bottom: 1rem;
        color: white;
      }
    }

    &-btn {
      padding: 10px 50px;

      font-size: 25px;
      font-family: var(--montserrat-font);

      border: none;
      border-radius: 2rem;

      background: linear-gradient(
        45deg,
        var(--swiper-theme-color),
        var(--primary-color)
      );
      color: white;
      cursor: pointer;

      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      text-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
      &:focus {
        outline: none;
      }

      @media screen and (max-width: $size-min-lg) {
        padding: 0.5rem 2rem;
        font-size: 2em;
      }
    }
  }

  .intro-slide {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .intro-slide-container .intro-slide-item-list .slick-slider {
      .slick-prev,
      .slick-next {
        width: 20px;
        height: 50px;
      }
      .slick-prev {
        left: 15px;
        background: url('./Slide_L.svg');
        background-repeat: no-repeat;
        &:before {
          content: '';
        }
      }

      .slick-next {
        right: 15px;
        background: url('./Slide_R.svg');
        background-repeat: no-repeat;
        &:before {
          content: '';
        }
      }

      .slick-arrow {
        z-index: 10;
      }
    }
  }

  &.out {
    .intro-title {
      opacity: 0;
      bottom: 50px;
    }
    .intro-slide {
      opacity: 0;
      top: 50px;
    }
  }

  &.in {
    .intro-title {
      opacity: 1;
      bottom: 0;
    }
    .intro-slide {
      opacity: 1;
      top: 0;
    }
  }
}
