.social-backdrop {
  position: absolute;
  top: 45px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 575px) {
    top: 0;
  }
  .social-title {
    font-size: 3.5rem;
    padding-bottom: 30px;
  }

  .social-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 480px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 70vw;
    }

    .social-icon {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
