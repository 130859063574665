.aqua-menu {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &-right__menu {
    pointer-events: all;
    position: absolute;
    top: 20vh;
    right: 0;
  }

  &-right__menu-icon {
    position: relative;
    cursor: pointer;
    background-color: white;
    padding: 8px;
    border: 1px solid rgba(gray, 0.1);

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom: 1px solid #03325c;
    }

    &:nth-child(2) {
      border-bottom-left-radius: 10px;
      transition: 0.3s ease;
      &:hover {
        background-color: #03325c;
        img {
          filter: invert(100%) !important;
        }
      }
    }
  }

  &-right__menu-icon-scenes {
    position: absolute;
    top: -50%;
    right: 100%;
    opacity: 0;
    transition: 0.3s ease;

    &.hovering {
      opacity: 1;
    }
  }

  &-right__menu-icon-scenes__header {
    width: 250px;
    height: 40px;
    padding: 0 12px;
    background-color: rgba($color: #03325c, $alpha: 0.9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);

    &-title {
      color: white;
      font-size: 13px;
    }
  }

  &-right__menu-icon-scenes__item {
    width: 250px;
    height: 40px;
    padding: 0 12px;
    background-color: rgba($color: white, $alpha: 0.9);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);

    &:hover {
      background-color: rgba($color: #2c8f9f, $alpha: 0.9);

      .aqua-menu-right__menu-icon-scenes__item-title {
        color: white;
      }
    }
  }

  &-right__menu-icon-scenes__item-title {
    color: #052044;
    font-size: 13px;
  }
}

.tour-menu.aqua-menu-gallery {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background-color: rgba(51, 103, 163, 0.5);
  backdrop-filter: blur(10px);
  pointer-events: all;
  z-index: 2;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;

  height: 100vh;
  padding: 50px 0 40px 0;

  .arrow-up,
  .arrow-down,
  .arrow-right,
  .arrow-left {
    position: absolute;
    font-size: 20px;
    line-height: 1;
    color: white;
  }

  .arrow-up {
    top: 0;
    rotate: 90deg;

    &__school-layout {
      top: 150px;
    }
  }

  .arrow-down {
    bottom: 22px;
    rotate: -90deg;
  }

  .arrow-right {
    bottom: 50%;
    right: 2px;
    transform: translateY(-50%);
    rotate: 180deg;
  }

  .arrow-left {
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
  }

  .menu-items {
    height: 100%;
    width: 100%;

    @media (min-width: 481px) {
      .scrollbar-container.ps.ps--active-y {
        .ps__rail-y {
          left: 0;
        }

        .ps__thumb-y {
          position: absolute;
          left: 0;
        }
      }
    }

    .menu-card {
      display: inline-block;
      position: relative;
      aspect-ratio: 16 / 9;
      height: 100%;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 10px;
      border: 2px solid transparent;

      @media (min-width: 481px) {
        position: relative;
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 5px;
        border: 2px solid transparent;
        cursor: pointer;
      }

      .menu-image {
        position: absolute;
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }

        &__scene-title {
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 30px;
          background-color: rgba(0, 0, 0, 0.6);
          p {
            color: white;
            font-size: 16px;
          }
        }
      }

      .menu-title {
        position: absolute;
        top: 5px;
        left: 5px;
        padding: 4px 10px;
        border-radius: 12px;
        color: white;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    .menu-card:last-child {
      margin-bottom: 0;
    }

    &__school-layout {
      height: 76%;
    }

    &-aqua {
      position: relative;
      border-top: 1px solid rgba($color: white, $alpha: 0.2);
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid rgba($color: white, $alpha: 0.2);
      }

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;
      }

      &__title {
        text-transform: uppercase;
        color: white;
        font-size: 13px;
        transition: 0.3s ease;

        &.hovering {
          color: #f9c468;
        }
      }

      &__icon {
        height: 20px;
        transition: 0.3s ease;
      }

      &__scenes {
        position: absolute;
        top: 0;
        left: 261px;
        transition: 0.5s ease;
        opacity: 0;
        pointer-events: none;
        z-index: 2;

        &.hovering {
          opacity: 1;
          pointer-events: all;
        }
      }

      &__scenes-item {
        width: 250px;
        height: 40px;
        padding: 0 12px;
        background-color: rgba($color: white, $alpha: 0.8);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);

        &:hover {
          background-color: rgba($color: #052044, $alpha: 0.8);
        }

        &.currentScene {
          background-color: #052044;
        }
      }

      &__scenes-item-title {
        font-size: 13px;
        color: #052044;

        &.hovering {
          color: white;
        }
      }

      &__scenes-item-imgTitle {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-size: 14px;
          color: #052044;
          text-transform: capitalize;
        }
      }

      &__scenes-item-image {
        position: absolute;
        top: 0;
        left: 251px;
        width: 300px;
        height: 220px;
        padding: 8px;
        background-color: white;
        transition: 0.5s ease;
        pointer-events: none;

        img {
          width: 100%;
          height: calc(100% - 36px);
          object-fit: cover;
        }
      }

      &__links {
        margin-top: 60px;
        padding: 0 15px;
      }

      &__links-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &__links-item {
        height: 32px;
        width: 32px;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        background-color: #03325c;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        i {
          font-size: 15px;
          color: white;
        }
      }

      &__weather {
        width: 100%;
        position: absolute;
        bottom: 0;
        margin: 15px;
        text-align: center;
        transition: 0.3s ease;

        &.translateX {
          transform: translateX(300px);
        }
      }

      &__weather-title {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        color: #f9c468;
        text-shadow: 1.5px 1px 1px rgba($color: gray, $alpha: 0.5);

        &.changeColor {
          color: white;
        }
        i {
          font-size: 20px;
          margin: 0 6px;
        }
      }
    }
  }

  .menu-items.menu-nonwrap {
    white-space: nowrap;
    overflow-x: hidden;
  }

  .menu-toggle {
    // @media (min-width: 481px) {
    position: absolute;
    bottom: -25px;
    transform: translateY(-50%);
    right: -50px;
    color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 0 10px 10px 0;
    cursor: pointer;
    background-color: rgba(21, 83, 141, 0.5);
    z-index: 1;

    .menu-button {
      width: 28px;
      height: 18px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
    }

    .menu-bar {
      width: 100%;
      height: 1.5px;
      background-color: white;
      border-radius: 2px;
    }

    .menu-open .menu-bar:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .menu-open .menu-bar:nth-child(2) {
      opacity: 0;
    }

    .menu-open .menu-bar:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }

    &-school__layout {
      bottom: 130px;
      left: -5px;
      top: unset;

      @media (min-width: 481px) {
        bottom: -10px;
        right: -45px;
        left: unset;
      }

      @media (min-width: 1441px) {
        bottom: 0;
        right: -50px;
      }
    }

    .menu-arrow-left {
      font-size: 20px;
      line-height: 1;
      transition: transform 0.3s ease;
      color: #e81b2a;
    }

    .menu-arrow-down {
      font-size: 20px;
      line-height: 1;
      transform: rotate(-90deg);
      transition: transform 0.3s ease;
    }
  }

  &.menu-close {
    // transform: translateY(100%);
    transition: transform 0.3s ease;

    // @media (min-width: 481px) {
    transform: translateX(-100%);
    // }
    .menu-toggle {
      .menu-arrow-left {
        transform: rotate(180deg);
      }
      .menu-arrow-down {
        transform: rotate(90deg);
      }
    }
  }

  &.menu-open {
    // transform: translateY(0);
    transition: transform 0.3s ease;

    // @media (min-width: 481px) {
    transform: translateX(0);
    // }
  }

  &.desktop .menu-arrow-down {
    display: none;
  }

  &.mobile .menu-arrow-left {
    display: block;
  }

  .aacorporation {
    &-link {
      text-decoration: none;
    }

    &-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      margin: 20px 10px;
      padding: 10px;
      background-color: #e81b2a;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;

      p,
      a {
        color: white;
        font-size: 16px;
        line-height: 1.3;
        font-weight: bold;
      }
    }
  }
}

.school-layout {
  &__tour-logo {
    position: absolute;
    top: 35px;
    img {
      width: 175px;
      height: 135px;
      object-fit: contain;
    }
  }
}

.aquaMenu-mobile {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;

  &.openMenu {
    z-index: 1;
  }

  &-container__top {
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(51, 103, 163, 0.7);
    width: 100%;
    height: 50px;
    padding: 10px 0;
    backdrop-filter: blur(10px);

    @media (max-width: 1023px) {
      backdrop-filter: blur(0);
      background-color: transparent;
    }

    @media (max-width: 767px) {
      background-color: rgba(51, 103, 163, 0.7);
    }
  }

  &-logo {
    height: 35px;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-left__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .menu-toggle {
      position: relative;
      color: white;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: rgba(3, 50, 92, 0.8);
      z-index: 1;

      &.background {
        background-color: rgba(18, 63, 158, 0.6);
      }

      .menu-button {
        width: 26px;
        height: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
      }

      .menu-bar {
        width: 100%;
        height: 1.5px;
        background-color: white;
        border-radius: 2px;
        transition: 0.3s ease;
      }

      .menu-open .menu-bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      .menu-open .menu-bar:nth-child(2) {
        opacity: 0;
      }

      .menu-open .menu-bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  &-weather__title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    color: white;
    // color: #f9c468;
    text-shadow: 1.5px 1px 1px rgba($color: gray, $alpha: 0.5);

    &.changeColor {
      color: white;
    }
    i {
      font-size: 13px;
      margin: 0 6px;
    }

    @media (max-width: 1023px) {
      display: none;
    }

    @media (max-width: 767px) {
      display: block;
    }
  }

  &-container__bottom {
    pointer-events: all;
    position: relative;
    z-index: 2;
    height: calc(100vh - 50px);
    background-color: rgba(51, 103, 163, 0.7);
    backdrop-filter: blur(10px);
    transition: 0.3s ease;

    &.openMenu {
      transform: translateX(100vw);
    }

    @media (min-width: 768px) and (max-width: 1023px) {
      width: 40vw;
      right: -60vw;
    }
  }

  .menu-items {
    &-aqua {
      position: relative;
      border-top: 1px solid rgba($color: white, $alpha: 0.2);
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid rgba($color: white, $alpha: 0.2);
      }

      &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;
        &.background {
          background-color: #052044;
        }
      }

      &__title {
        text-transform: uppercase;
        color: white;
        font-size: 13px;
        transition: 0.3s ease;

        &.hovering {
          color: #f9c468;
        }

        @media (max-width: 767px) {
          font-size: 15px;
        }
      }

      &__icon {
        height: 20px;
        transition: 0.3s ease;
      }

      &__scenes {
        position: relative;
        transition: 0.5s ease;
        opacity: 0;
        pointer-events: none;
        z-index: 2;
        max-height: 0;

        &.hovering {
          opacity: 1;
          pointer-events: all;
          max-height: 500px;
        }
      }

      &__scenes-item {
        width: inherit;
        height: 40px;
        padding: 0 12px;
        background-color: rgba($color: white, $alpha: 0.8);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dotted rgba($color: black, $alpha: 0.2);

        &:hover {
          background-color: rgba($color: #052044, $alpha: 0.8);
        }

        &.currentScene {
          background-color: #052044;
        }

        @media (max-width: 1023px) {
          justify-content: flex-start;
        }
      }

      &__scenes-item-title {
        font-size: 13px;
        color: #052044;

        &.hovering {
          color: white;
        }

        @media (max-width: 1023px) {
          font-size: 13px;
          margin-left: 15px;
        }
      }

      &__scenes-item-imgTitle {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-size: 14px;
          color: #052044;
          text-transform: capitalize;
        }
      }

      &__scenes-item-image {
        position: absolute;
        top: 0;
        left: 251px;
        width: 300px;
        height: 220px;
        padding: 8px;
        background-color: white;
        transition: 0.5s ease;
        pointer-events: none;

        img {
          width: 100%;
          height: calc(100% - 36px);
          object-fit: cover;
        }
      }

      &__links {
        margin-top: 60px;
        padding: 0 80px;
      }

      &__links-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      &__links-item {
        height: 32px;
        width: 32px;
        padding: 10px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 50%;
        background-color: #03325c;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        i {
          font-size: 15px;
          color: white;
        }
      }

      &__weather {
        width: 100%;
        position: absolute;
        bottom: 0;
        margin: 15px;
        text-align: center;
        transition: 0.3s ease;

        &.translateX {
          transform: translateX(300px);
        }
      }

      &__weather-title {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        color: #f9c468;
        text-shadow: 1.5px 1px 1px rgba($color: gray, $alpha: 0.5);

        &.changeColor {
          color: white;
        }
        i {
          font-size: 20px;
          margin: 0 6px;
        }
      }
    }
  }
}

.aqua-navInfo {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  z-index: 1;

  &-title {
    font-size: 17px;
    color: white;
    // text-shadow: 1px 1px 1px rgba($color: gray, $alpha: 0.5);
    margin-left: 7px;
    text-transform: uppercase;
  }

  &-arrow {
    margin-left: 7px;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1023px) {
    right: 55px;
  }

  @media (max-width: 767px) {
    top: unset !important;
    bottom: 0;
    right: 0;
    left: 0px;
    width: 100vw;
    margin: 0;
    height: 36px;
    background-color: rgba(37, 88, 138, 0.7);
    backdrop-filter: blur(3px);

    &-title {
      font-size: 10px;
      color: white;
      font-weight: 500;
    }
  }
}

.aquaMenu-ipad {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 10px;
  &-weather__title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    color: white;
    text-shadow: 1.5px 1px 1px rgba($color: gray, $alpha: 0.5);

    &.changeColor {
      color: white;
    }
    i {
      font-size: 13px;
      margin: 0 6px;
    }
  }
}
