.khp-media-hotspot {
  width: 8px;
  height: 8px;
  background-color: #e3cf8f;
  // background-color: white;
  box-shadow: 1px 1px 1px black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;

  &::before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background-color: rgba(227, 207, 143, 0.5);
    // background-color: white;
    // box-shadow: 1px 1px 1px black;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: all;
  }

  .khp-media-hotspot__container {
    pointer-events: none;
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;

    & > * {
      pointer-events: all;
    }

    &::after {
      content: '';
      width: 0px;
      height: auto;
      border-right: 1px dashed #ffc000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-1px);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
    }
  }

  .khp-media-hotspot__title {
    position: absolute;
    width: auto;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    // background-color: white;
    background-color: rgba(239, 201, 86, 0.687);
    border-radius: 4px;
    padding: 5px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);

    .khp-media-hotspot__preview {
      display: none;
    }

    // &:hover {
    //   background-color: rgba(255, 192, 0, 1);
    //   box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
    // }

    p {
      white-space: nowrap;
      margin: 0;
      text-align: center;
      color: #422213;
      font-size: 8px;
      text-transform: uppercase;
      font-weight: 700;
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);

      &.text-left {
        text-align: left;
      }

      @media (min-width: 1024px) {
        font-size: 10px;
      }
    }
  }

  &:hover {
    background-color: rgba(255, 192, 0, 1);

    &::before {
      background-color: rgba(225, 204, 133, 0.8);
    }

    .khp-media-hotspot__title {
      opacity: 1;
      background-color: rgba(255, 192, 0, 1);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);

      .khp-media-hotspot__preview {
        display: block;
        position: absolute;
        bottom: 100%;
        width: 200px;
        left: 50%;
        transform: translateX(-50%);
        right: 0;
        height: 120px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid rgb(214, 182, 35);
      }
    }
  }

  &.big-bottom {
    width: 30px;
    height: 30px;
    background-color: rgba(227, 207, 143, 0.5);
    pointer-events: all;
    cursor: pointer;
    box-shadow: none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      background-color: #e3cf8f;
      border-radius: 50%;
    }

    &::after {
      display: block;
      position: absolute;
      content: '';
      width: 20px;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      // border: 1px solid #d6b623;
      background-color: transparent;
      animation: mediaPulsePoint 1s infinite;
    }

    .khp-media-hotspot__container {
      bottom: 27px;
    }

    @keyframes mediaPulsePoint {
      0% {
        box-shadow: 0 0 0 0 rgb(255, 255, 255);
      }

      70% {
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }
  }
}
